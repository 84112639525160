<template>
  <a-checkbox :value="item.id || item.value" @change="onChange">
    <div style="display: inline-block;width：100%">
      <!-- <img
        class="approval-img"
        :src="item.avatarId ? item.fullAvatar : require('@/assets/images/default-avatar.png')"
      /> -->
      <p class="approval-info">
        <span
          class="single-wrap"
          :style="{ width: item.name.length > 5 || item.label.length > 5 ? '80px' : '' }"
          :title="item.label || item.name"
          >{{ item.label || item.name }}</span
        >
        <span>（{{ item.mobile }}）</span>
      </p>
    </div>
  </a-checkbox>
</template>

<script>
export default {
  name: 'approval-staff-item',
  props: ['item', 'onChange', 'index']
}
</script>

<style lang="scss" scoped>
.ant-checkbox-group {
  display: flex;
  flex-direction: column;
}
.approval-img {
  width: 32px;
  height: 32px;
  border-radius: 4px;
  object-fit: cover;
  transform: translateY(10px);
  margin-right: 4px;
}
.approval-info {
  font-size: 14px;
  font-family: PingFangSC, PingFangSC-Regular;
  font-weight: 400;
  color: #333333;
  line-height: 22px;
  display: flex;
  justify-content: flex-start;
  align-content: center;
}
</style>
